import { PaginationBody, PaginationHeader } from "@/types/Misc";

const PaginBodyDefault: PaginationBody = {
  pageSize: 50,
  pageNumber: 1,
  searchValue: '',
  orderBy: '',
  descending: true
}

const PaginHeaderDefault: PaginationHeader = {
  "X-PageNumber": PaginBodyDefault.pageNumber,
  "X-PageSize": PaginBodyDefault.pageSize,
  "X-Desc": PaginBodyDefault.descending,
  "X-Order": PaginBodyDefault.orderBy,
  "X-Query": PaginBodyDefault.searchValue
}

export { PaginBodyDefault, PaginHeaderDefault }