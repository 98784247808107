class TokenService {
  getLocalRefreshToken() {
    const user = this.getUserTokenData()
    return user?.refresh_token
  }
  getLocalAccessToken() {
    const user = this.getUserTokenData()
    return user?.access_token
  }
  updateLocalAccessToken(token: string) {
    const user = this.getUserTokenData()
    user.access_token = token
    localStorage.setItem("user", JSON.stringify(user))
  }
  getUserTokenData() {
    if (localStorage.getItem('user')) {
      return JSON.parse(localStorage.getItem('user') || '{}')
    }
    return null
  }
  setUserTokenData(user: any) {
    localStorage.setItem("user", JSON.stringify(user))
  }
  refreshUserTokenData(user: any) {
    const oldUser = this.getUserTokenData()
    oldUser.access_token = user.access_token
    oldUser.refresh_token = user.refresh_token
    oldUser.expires_in = user.expires_in
    localStorage.setItem('user', JSON.stringify(oldUser))
  }
  removeUserTokenData() {
    localStorage.removeItem("user")
  }
}

export default new TokenService()