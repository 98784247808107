
import api from '@/services/api'
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { Group } from '@/types/Groups'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from 'primevue/useconfirm'
import { PaginationBody } from '@/types/Misc'
import { PaginBodyDefault } from '@/consts/PaginationConst'
import { useStore } from 'vuex'
import { toastError } from '@/helpers/error-handling'

export default defineComponent({
  components: {},
  setup() {
    const router = useRouter()
    const store = useStore()
    const toast = useToast()
    const confirm = useConfirm()
    const groups = ref<Group[]>([])

    const tableBody = ref<PaginationBody>({ ...PaginBodyDefault })
    const groupCount = ref<number>(0)

    onMounted(() => {
      fetchGroups()
    })

    const tableUtilsVisible = computed(() => {
      return groups.value.filter(mod => mod.selected).length
    })
    const areGroupsSelected = computed(() => {
      return tableUtilsVisible.value ? true : false
    })

    const fetchGroups = () => {
      api.put('manager/group/filter', JSON.stringify(tableBody.value)).then(res => {
        groups.value = res?.data.data.list
        groupCount.value = res?.data.data.pageInfo.totalCount
      }).catch((err: Error) => toastError(toast, err))
    }

    const addGroup = () => {
      router.push('/add-group')
    }

    const deleteGroup = (group: Group) => {
      confirm.require({
        message: `Are you sure you want to delete group '${group.name}'?`,
        header: 'Warning',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          api.delete(`manager/group/${group.externalId}`).then(() => {
            toast.add({ severity: 'info', summary: `Group ${group.name} deleted`, life: 3000})
            store.dispatch('fetchGroups')
            return fetchGroups()
          }).catch((err: Error) => toastError(toast, err))
        }
      })
    }

    const groupDetails = (group: Group) => {
      router.push(`/group-details/${group.externalId}`)
    }

    const exportGroupResults = () => {
      toast.add({ severity: 'info', summary: 'Feature coming soon!', life: 3000})
    }

    const toggleAllGroups = () => {
      if (areGroupsSelected.value) {
        groups.value.forEach(item => item.selected = false)
      } else {
        groups.value.forEach(item => item.selected = true)
      }
    }

    const paginChange = (payload: PaginationBody) => {
      tableBody.value = { ...payload, pageNumber: payload.pageSize === tableBody.value.pageSize ? payload.pageNumber : 1 }
      fetchGroups()
    }

    return {
      groups,
      tableUtilsVisible,
      areGroupsSelected,
      tableBody,
      groupCount,
      addGroup,
      deleteGroup,
      groupDetails,
      exportGroupResults,
      toggleAllGroups,
      paginChange
    }
  },
})
