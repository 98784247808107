
import api from '@/services/api'
import { JobItem } from '@/types/Tools'
import { AxiosResponse } from 'axios'
import { defineComponent, onMounted, ref } from 'vue'
import { parseDateLongFormat } from '@/helpers/table-utils'
import { toastError } from '@/helpers/error-handling'
import { useToast } from 'primevue/usetoast'

export default defineComponent({
  components: {},
  setup() {
    const toast = useToast()
    
    const results = ref<JobItem[]>([{} as JobItem])
    let checkInterval: any = null

    onMounted(() => {
      fetchResults()
    })

    const fetchResults = () => {
      api.get('manager/usersync/all').then((res: AxiosResponse) => {
        results.value = res.data.data
        results.value.sort((a: JobItem, b: JobItem) => b.created - a.created)
        results.value.forEach((item: JobItem) => {
          item.completion = Math.round(item.current / item.count * 10000) / 100
        })
        if (results.value.filter(item => !item.isFinished).length === 0) {
          clearInterval(checkInterval)
        } else if (!checkInterval) {
          checkInterval = setInterval(() => fetchResults(), 2500)
        }
      })
    }

    const exportItem = (item: JobItem) => {
      api.get(`manager/usersync/${item.externalId}/excel`)
        .then((res) => {
          const url = URL.createObjectURL(new Blob([res.data], {
            type: 'text/csv'
          }))
          const fileName = `voucher-export-${Date.now()}.csv`
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          link.remove()
        })
        .catch((err: any) => toastError(toast, err))
    }

    return {
      results,
      parseDateLongFormat,
      exportItem
    }
  },
})
