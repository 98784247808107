
import api from '@/services/api'
import { defineComponent, ref, computed } from 'vue'
import { RouterLink } from 'vue-router'
import { PaginationBody, PaginationHeader } from '@/types/Misc'
import { BlendedCareUser } from '@/types/User'
import { format } from 'date-fns'
import { PaginBodyDefault, PaginHeaderDefault } from '@/consts/PaginationConst'
import { useStore } from 'vuex'
import { AccessRole } from '@/enums/UserRole'
import { Group } from '@/types/Groups'
import { BlendedCareCall, BlendedCareCallRequest } from '@/types/BlendedCare'
import { parseDateLongFormat } from '@/helpers/table-utils'
import { useToast } from 'primevue/usetoast'
import { toastError } from '@/helpers/error-handling'

export default defineComponent({
  components: {
    RouterLink
  },
  setup() {
    const store = useStore()
    const toast = useToast()
    const tableBody = ref<PaginationBody>({ ...PaginBodyDefault })
    const callsTableHeader = ref<PaginationHeader>({ ...PaginHeaderDefault })

    store.dispatch('fetchBlendedCare', tableBody.value)
    const groups = computed<Group[]>(() => store.state.groupList)

    const users = computed<BlendedCareUser[]>(() => store.state.blendedCareList)
    const userCount = computed<number>(() => store.state.blendedCareCount)
    const userRole = computed(() => store.state.userType)

    let searchTimeout = ref<number | null>(0)

    const callModalOpen = ref(false)
    const blendedCareCalls = ref<BlendedCareCall[]>([] as BlendedCareCall[])
    const calendarValue = ref<string | null>(null)

    const returnDate = (ts: number): string => {
      return ts ? format(new Date(ts * 1000), 'dd-MM-yyyy HH:mm:ss') : '-'
    }

    const searchUsers = () => {
      if (searchTimeout.value) {
        clearTimeout(searchTimeout.value)
        searchTimeout.value = null
      }
      searchTimeout.value = window.setTimeout(() => {
        const searchLength = tableBody.value.searchValue.length
        if (searchLength >= 3 || searchLength === 0) {
          tableBody.value.pageNumber = 1
          store.dispatch('fetchBlendedCare', tableBody.value)
        }
      },800)
    }

    const paginChange = (payload: PaginationBody) => {
      tableBody.value = { ...payload }
      store.dispatch('fetchBlendedCare', tableBody.value)
    }

    const findGroup = (groupId: string): Group | undefined => {
      return groups.value.find(item => item.externalId === groupId)
    }

    const clearCall = (call: BlendedCareCall) => {
      api.delete(`manager/blendedcare/calls/${call.externalId}`,{
        headers: {
        }
      })
          .then(res => {
            toast.add({ severity: 'info', summary: 'Call deleted', detail: `Call '${call.name}' has been deleted`, life: 3000 })
          })
          .catch((err: any) => toastError(toast, err))
      call.timeOfCall = undefined;
    }
    
    const openCallsModal = (user: BlendedCareUser) => {
      api.get(`manager/blendedcare/calls/${user.externalId}`,{
        headers: {
          ...callsTableHeader.value
        }
      })
      .then(res => {
        blendedCareCalls.value = res.data.data
        callModalOpen.value = true
      })
      .catch((err: Error) => toastError(toast, err))
    }

    const setCallTime = (call: BlendedCareCall) => {
      call.timeOfCall = Date.parse(calendarValue.value || "") / 1000

      const callUpdate: BlendedCareCallRequest = {
        externalId: call.externalId,
        isSuccess: true,
        timeOfCall: call.timeOfCall
      }

      api.post('manager/blendedcare/calls', JSON.stringify(callUpdate))
        .then(() => {
          toast.add({ severity: 'info', summary: 'Call updated', detail: `Call '${call.name}' has been updated`, life: 3000 })
        })
        .catch((err: Error) => toastError(toast, err))
    }

    return {
      users,
      userCount,
      tableBody,
      userRole,
      AccessRole,
      callModalOpen,
      blendedCareCalls,
      calendarValue,
      clearCall,
      parseDateLongFormat,
      searchUsers,
      returnDate,
      paginChange,
      findGroup,
      openCallsModal,
      setCallTime
    }
  },
})
