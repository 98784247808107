class ImageValidator {
  private readonly maxSize: number
  private readonly maxWidth: number
  private readonly maxHeight: number

  constructor(maxWidth = 900, maxHeight = 900, maxSize = 2000000) {
    this.maxWidth = maxWidth
    this.maxHeight = maxHeight
    this.maxSize = maxSize
  }

  private checkSize(fileSize: number): boolean {
    return fileSize <= this.maxSize
  }

  private checkDimensions(width: number, height: number): boolean {
    return width <= this.maxWidth && height <= this.maxHeight
  }

  validateImage(image: HTMLImageElement, file: File): boolean {
    const { width, height } = image
    const { size } = file
    return this.checkSize(size) && this.checkDimensions(width, height)
  }
}

export default ImageValidator