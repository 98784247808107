
import { toastError } from '@/helpers/error-handling'
import api from '@/services/api'
import { useToast } from 'primevue/usetoast'
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {},
  setup() {
    const toast = useToast()
    const router = useRouter()
    const urlParams = new URLSearchParams(window.location.search)
    const email = decodeURIComponent(escape(window.atob(urlParams.get('email') || '')))
    const token = decodeURIComponent(escape(window.atob(urlParams.get('token') || '')))
    const password = ref<string>('')
    const passwordConfirm = ref<string>('')
    const passwordRegValid = ref<boolean>(true)
    const passwordMatchValid = ref<boolean>(true)
    const passwordSet = ref<boolean>(false)

    const confirmCred = () => {
      checkPasswordReg()
      checkPasswordMatch()

      if (passwordRegValid.value && passwordMatchValid.value) {
        const body = {
          token: token,
          email: email,
          password: password.value
        }
  
        api.post('/user/identity/confirmpassword', JSON.stringify(body))
        .then(() => {
          toast.add({ severity: 'info', summary: 'New password set!', life: 3000})
          router.push('/login')
        }).catch((err: any) => toastError(toast, err))
      }
    }

    const checkPasswordReg = () => {
      const regularExpression = /^(?=.{6})(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])/

      passwordRegValid.value = regularExpression.test(password.value)
    }

    const checkPasswordMatch = () => {
      passwordMatchValid.value = password.value === passwordConfirm.value
    }

    const clearErrors = (): void => {
      passwordRegValid.value = true
      passwordMatchValid.value = true
    }

    return { confirmCred, clearErrors, password, passwordConfirm, passwordSet, passwordRegValid, passwordMatchValid }
  },
})
