
import api from '@/services/api'
import { defineComponent, ref, nextTick, computed, onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useToast } from 'primevue/usetoast'
import { Group } from '@/types/Groups'
import { Insurer, InsurerRequest } from '@/types/Insurer'
import { FileUploadSelectEvent } from 'primevue/fileupload'
import imageValidator from '@/classes/ImageValidatorClass'
import { toastError } from '@/helpers/error-handling'
import { addInsurerFiles, onInsurerFileUpload } from '@/helpers/insurer-form-handling'

export default defineComponent({
  props: ['id'],
  components: {},
  setup(props) {
    const router = useRouter()
    const store = useStore()
    const toast = useToast()
    const validator = new imageValidator()
    const insurer = ref<Insurer>({} as Insurer)
    const newInsurer = ref<InsurerRequest>({} as InsurerRequest)
    const formValid = ref<boolean>(false)
    const fieldValidation = reactive({
      name: '',
      group: '',
      slug: ''
    })
    const files = reactive({
      image: null,
      impressum: null,
      terms: null,
      management: null,
      patient: null,
      participation: null
    })
    const previewImage = ref('')
    const uploadReady = ref(true)
    const selectedGroup = ref<Group>({} as Group)
    const groupList = computed<Group[]>(() => store.state.groupList)
    const groupError = ref<boolean>(false)

    onMounted(() => {
      fetchData()
    })

    const fetchData = () => {
      api.get(`manager/insurer/${props.id}`)
        .then(res => {
          insurer.value = res?.data.data
        })
    }

    const handleSubmit = () => {
      newInsurer.value = {
        externalId: props.id,
        name: insurer.value.name,
        isExternal: insurer.value.isExternal,
        groupExternalId: insurer.value.group.externalId,
        url: insurer.value.isExternal ? insurer.value.url : insurer.value.slug,
        slug: insurer.value.slug,
      }
      
      validateForm()

      if (!formValid.value) {
        return false
      }

      api.put('manager/insurer', JSON.stringify(newInsurer.value))
        .then(res => {
          return Promise.allSettled(addInsurerFiles(res?.data.data.externalId, files))
            .then(results => {
              if (results.length) {
                return Promise.all(results.map((result) => {
                  if (result.status === 'fulfilled') {
                    toast.add({ severity: 'info', summary: 'Insurer updated', life: 3000 })
                    router.push('/insurers')
                  } else {
                    toastError(toast, result.reason);
                    return null;
                  }
                }))
              } else {
                toast.add({ severity: 'info', summary: 'Insurer added', life: 3000 })
                router.push('/insurers')
              }
            })
          })
        .catch((err: Error) => toastError(toast, err))
    }

    const validateForm = () => {
      const regex = /^[a-zA-Z0-9]{3,20}$/
      fieldValidation.name = newInsurer.value.name.length <= 0 ? 'Group must have name' : ''
      fieldValidation.group = !newInsurer.value.groupExternalId ? 'Please select a group' : ''
      if (newInsurer.value.slug) {
        fieldValidation.slug = !regex.test(newInsurer.value.slug) ? 'Slug should contain only numbers and letters (min 3, max 20 characters)' : ''
      }

      formValid.value = !fieldValidation.name.length && !fieldValidation.group.length && !fieldValidation.slug.length
    }

    const resetUpload = async () => {
      uploadReady.value = false
      await nextTick()
      uploadReady.value = true
    }

    const onImageSelect = (event: FileUploadSelectEvent) => {
      const file = event.files[0]
      if (!file) return

      files.image = file
      const reader = new FileReader()
      reader.onload = () => {
        const image = new Image()
        image.onload = async () => {
          if (validator.validateImage(image, file)) {
            previewImage.value = reader.result as string
          } else {
            files.image = null
            await resetUpload()
            alert('Invalid image dimensions or size.')
          }
        }
        image.src = reader.result as string
      }
      reader.readAsDataURL(file)
    }

    const clearPreview = () => {
      previewImage.value = ''
    }

    const deleteImage = () => {
      api.delete(`manager/insurer/asset/${insurer.value.externalId}/logo`)
        .then(() => fetchData())
        .catch(err => console.log(err.message))
    }

    return {
      insurer,
      newInsurer,
      formValid,
      fieldValidation,
      previewImage,
      uploadReady,
      selectedGroup,
      groupList,
      groupError,
      files,
      handleSubmit,
      onImageSelect,
      clearPreview,
      onInsurerFileUpload,
      deleteImage
    }
  }
})
