import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "header__right-side" }
const _hoisted_3 = { class: "header__user" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_Menu = _resolveComponent("Menu")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Spinner),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Avatar, {
          icon: "pi pi-user",
          class: "mr-2",
          shape: "circle",
          onClick: _ctx.toggle
        }, null, 8, ["onClick"]),
        _createVNode(_component_Menu, {
          model: _ctx.items,
          ref: "menu",
          popup: true
        }, null, 8, ["model"])
      ])
    ])
  ]))
}