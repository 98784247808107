
import { AccessRole } from '@/enums/UserRole'
import { Group } from '@/types/Groups'
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import AutoLogoutComponent from './AutoLogoutComponent.vue'

export default defineComponent({
  components: { AutoLogoutComponent },
  setup() {
    const store = useStore()
    const accessIsSet = ref<boolean>(false)

    const isVisibleFor = ((roles: string[]): boolean => {
      return roles.includes(store.state.userType)
    })

    const groupsMenu = computed(() => {
      if (!isVisibleFor([AccessRole.Administrator, AccessRole.Manager])) {
        return null
      }

      return store.state.groupList.map((group: Group) => {
        return({
          name: group.name,
          to: `/users/${group.externalId}`,
          visible: true
        })
      }).sort((a: Group, b: Group) => 
        (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : (b.name.toLowerCase() > a.name.toLowerCase()) ? -1: 0)
    })

    const showSubmenu = ref<boolean>(false)

    const menu = computed(() => [
      {
        name: 'Users',
        to: '/users/all',
        visible: isVisibleFor([AccessRole.Administrator, AccessRole.Manager]),
      },
      {
        name: 'Groups',
        to: '/groups',
        visible: isVisibleFor([AccessRole.Administrator, AccessRole.Manager]),
        menuOpen: showSubmenu.value,
        children: groupsMenu.value
      },
      {
        name: 'Vouchers',
        to: '/vouchers',
        visible: isVisibleFor([AccessRole.Administrator, AccessRole.Manager]),
      },
      {
        name: 'Management',
        to: '/management',
        visible: isVisibleFor([AccessRole.Administrator]),
      },
      {
        name: 'Blended Care',
        to: '/blended-care',
        visible: true
      },
      {
        name: 'Deletion',
        to: '/deletion',
        visible: isVisibleFor([AccessRole.Administrator, AccessRole.Manager]),
      },
      {
        name: 'Insurers',
        to: '/insurers',
        visible: isVisibleFor([AccessRole.Administrator, AccessRole.Manager]),
      },
      {
        name: 'Tools',
        to: '/tools',
        visible: isVisibleFor([AccessRole.Administrator, AccessRole.Manager])
      },
    ])

    onMounted(() => {
      store.dispatch('fetchGroups')
      setUser()
    })

    const setUser = async () => {
      await store.dispatch('setUserRole')
      accessIsSet.value = true
    }
    
    const toggleMenu = (item: any) => {
      showSubmenu.value = !showSubmenu.value
    }

    return { menu, accessIsSet, toggleMenu }
  },
})
