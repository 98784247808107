import axios from "axios"

let baseUri = process.env.VUE_APP_BASE_URI
const instance = axios.create({
  baseURL: baseUri,
  headers: {
    'Content-Type': 'application/json',
  },
})

fetch(process.env.BASE_URL + 'config.json')
  .then(res => res.json())
  .then(config => {
    baseUri = config.BASE_URI !== '$BASE_URI' ? config.BASE_URI : baseUri
  })
  .then(() => {
    instance.defaults.baseURL = baseUri
  }).catch((err: any) => console.log('axios api error: ', err))

export default instance