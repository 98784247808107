
import { defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'AutoLogoutComponent',
  setup() {
    const store = useStore()
    const router = useRouter()
    const events = ['click', 'mousemove', 'scroll']

    let warningTimer: any = null
    let logoutTimer: any = null

    const warningMessageVisible = ref(false)

    onMounted(() => {
      events.forEach(event => {
        window.addEventListener(event, resetTimer)
      })
    })

    const setTimers = () => {
      warningTimer = setTimeout(warning, 55 * 60 * 1000) // Warning at 55 min
      logoutTimer = setTimeout(logout, 60 * 60 * 1000) // Logout at 60 min
    }

    const warning = () => {
      warningMessageVisible.value = true
    }

    const logout = async () => {
      await store.dispatch('logout')
      router.push('/login')
    }

    const resetTimer = () => {
      clearTimeout(warningTimer)
      clearTimeout(logoutTimer)
      warningMessageVisible.value = false
      setTimers()
    }

    return {
      warningMessageVisible
    }
  },
})
