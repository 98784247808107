import { ToastServiceMethods } from "primevue/toastservice";

export function handleError(err: any): string {
  if (err?.response?.status === 400) {
    return extractErrorMsg(err) ? extractErrorMsg(err) : "Bad Request";
  } else if (err?.response?.status === 401) {
    return "Unauthorized";
  } else if (err instanceof Error) {
    return err.message;
  } else {
    return `Unexpected error: ${err}`;
  }
}

const extractErrorMsg = (err: any): string => {
  return err.response.data.errors[0].message || ''
}

export function toastError(toast: ToastServiceMethods, err: any, customMsg?: string) {
  toast.add({ severity: 'error', summary: customMsg ? customMsg : handleError(err), life: 3000})
}