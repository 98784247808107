
import api from '@/services/api'
import { UserType } from '@/enums/UserType'
import { Group } from '@/types/Groups'
import { DropdownItem } from '@/types/Misc'
import { UserRequest } from '@/types/User'
import { useToast } from 'primevue/usetoast'
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { toastError } from '@/helpers/error-handling'

export default defineComponent({
  props: ['group'],
  components: {},
  setup(props) {
    const router = useRouter()
    const store = useStore()
    const toast = useToast()

    const userTypeEnum = UserType
    const userTypeOptions = Object.keys(userTypeEnum).map(key => ({
      label: userTypeEnum[key as keyof typeof userTypeEnum], value: key
    } as DropdownItem))
    
    const user = ref<UserRequest>({} as UserRequest)
    const codeErrorMsg = ref<string>('')
    const groups = computed<Group[]>(() => store.state.groupList)
    const selectedGroup = ref<Group>({} as Group)

    onMounted(() => {
      store.dispatch('fetchGroups')
      
      const defaultGroup = props.group !== 'all' ? groups.value.filter(item => item.externalId === props.group)[0].name : 'Default'
      selectedGroup.value = groups.value.filter(item => item.name === defaultGroup)[0]
    })

    const checkLoginCode = () => {
      codeErrorMsg.value = ''
      if (user.value.code) {
        const regex = /^[a-zA-Z0-9]*$/
        codeErrorMsg.value = !regex.test(user.value.code) ? 'Code should contain only numbers and letters' : ''
      }
    }
    const handleSubmit = async () => {
      checkLoginCode()
      if (!codeErrorMsg.value.length) {
        const newUser: UserRequest = { ...user.value, groupExternalId: selectedGroup.value.externalId }
        api.post('manager/user', JSON.stringify(newUser))
          .then(() => {
            router.push('/users/all')
          }).catch((err: any) => toastError(toast, err))
      }
    }

    return {
      userTypeOptions,
      user,
      codeErrorMsg,
      groups,
      selectedGroup,
      handleSubmit
    }
  },
})
