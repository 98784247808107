interface DropdownItem {
  label: string
  value: string | number
}

interface DropdownItemWithStatus extends DropdownItem {
  disabled: boolean
}

interface PaginationBody {
  pageSize: number,
  pageNumber: number,
  searchValue: string,
  orderBy: string,
  descending: boolean
}

interface PaginationHeader {
  'X-PageSize'?: number,
  'X-PageNumber': number,
  'X-Order'?: string,
  'X-Query'?: string,
  'X-Desc': boolean
}

interface Error {
  code?: string,
  message?: string
}

interface FilterOptions {
  config: {
    descending: boolean,
    enabled: boolean
  }
}

type FiltrationItem<T extends string> = {
  [K in T]: FilterOptions
}

export const keys = Object.keys as <T>(o: T) => Extract<keyof T, string>[];

export const values = Object.values as <T>(
  o: T
) => T[Extract<keyof T, string>][];

export const entries = Object.entries as <T>(
  o: T
) => [Extract<keyof T, string>, T[Extract<keyof T, string>]][];

export { DropdownItem, DropdownItemWithStatus, PaginationBody, PaginationHeader, Error, FiltrationItem }