import api from '@/services/api'

export const uploadInsurerFile = async (url: string, file: any) => {
  const formData = new FormData()
  formData.append("file", file)
  await api.put(url, formData)
}

export const addInsurerFiles = (externalId: string, fileObj: any) => {
  const promises: Promise<any>[] = [];

  if (fileObj.image) {
    promises.push(uploadInsurerFile(`/manager/insurer/asset/${externalId}/logo`, fileObj.image))
  }
  if (fileObj.impressum) {
    promises.push(uploadInsurerFile(`/manager/insurer/asset/${externalId}/impressum`, fileObj.impressum))
  }
  if (fileObj.terms) {
    promises.push(uploadInsurerFile(`/manager/insurer/asset/${externalId}/termsandregulations`, fileObj.terms))
  }
  if (fileObj.management) {
    promises.push(uploadInsurerFile(`/manager/insurer/asset/${externalId}/datamanagement`, fileObj.management))
  }
  if (fileObj.patient) {
    promises.push(uploadInsurerFile(`/manager/insurer/asset/${externalId}/patientinformation`, fileObj.patient))
  }
  if (fileObj.participation) {
    promises.push(uploadInsurerFile(`/manager/insurer/asset/${externalId}/declarationofparticipation`, fileObj.participation))
  }

  return promises;
}

export const onInsurerFileUpload = (event: any, type: string, filesObj: any) => {
  switch (type) {
    case 'terms':
      filesObj.terms = event.files[0]
      break
    case 'data':
      filesObj.management = event.files[0]
      break
    case 'impressum':
      filesObj.impressum = event.files[0]
      break
    case 'patient':
      filesObj.patient = event.files[0]
      break
    case 'participation':
      filesObj.participation = event.files[0]
      break
    default:
      break
  }
}