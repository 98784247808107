
import { AccessRole } from '@/enums/UserRole'
import { defineComponent, ref, onMounted } from 'vue'
import { useRouter, RouterLink } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
  components: {
    RouterLink
  },
  setup() {
    const router = useRouter()
    const store = useStore()

    const username = ref<string>('')
    const password = ref<string>('')
    const formErr = ref<string>('')
    const code = ref<string>('')
    const codeConfirmVisible = ref<boolean>(false)
    const counter = ref<number>(0)
    let checkInterval: any = null

    onMounted(() => {
      store.dispatch('cleanUser')
    })

    const confirmCred = async () => {
      const data: URLSearchParams = new URLSearchParams({
        username: username.value,
        grant_type: 'password',
        password: password.value,
        scope: 'api1 offline_access'
      })
      try {
        await store.dispatch('login', {user: data, lastStep: false})
        codeConfirmVisible.value = true
        formErr.value = ''
      } catch (err: any) {
        formErr.value = err.response.data ? err.response.data : 'Wrong username and/or password.'
      }
    }

    const confirmCode = async () => {
      const data: URLSearchParams = new URLSearchParams({
        authValue: username.value,
        grant_type: 'auth_code',
        userCode: code.value,
        scope: 'api1 offline_access'
      })
      try {
        await store.dispatch('login', {user: data, lastStep: true})
        store.state.userType !== AccessRole.Telecoach ? router.push('/users/all') : router.push('/blended-care')
      }
      catch (err: any) {
        formErr.value = err.response.data ? err.response.data : 'Wrong SMS code.'
      }
    }

    const resendCode = () => {
      confirmCred()
      counter.value = 60
      checkInterval = setInterval(() => {
        if (counter.value) {
          counter.value--
        } else {
          clearInterval(checkInterval)
        }
      }, 1000)
    }

    return {
      username,
      password,
      formErr,
      code,
      codeConfirmVisible,
      counter,
      confirmCred,
      confirmCode,
      resendCode
    }
  },
})
