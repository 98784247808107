
import { computed } from '@vue/reactivity'
import { defineComponent } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'SpinnerComponent',
  components: {},
  setup() {
    const store = useStore()
    const loading = computed<boolean>(() => store.state.status.loading)

    return { loading }
  },
})
