
import { UploadedUserType } from '@/enums/UserType'
import api from '@/services/api'
import { CreateUserResponse, UserUploadEnv } from '@/types/User'
import { defineComponent, ref } from 'vue'
import { useToast } from 'primevue/usetoast'
import { toastError } from '@/helpers/error-handling'

export default defineComponent({
  components: {},
  setup() {
    const uploadedUserTypeEnum = UploadedUserType
    const toast = useToast()
    const tableUsers = ref<CreateUserResponse[]>([])
    const errorMsg = ref<string>('')
    const isUploading = ref(false)
    const progressPercentage = ref<number>(0)
    let checkInterval: any = null

    const onUpload = (event: any) => {
      isUploading.value = true
      const formData = new FormData()
      formData.append("file", event.files[0])
      let sessionId = ''
      api.post('manager/import/job/upload', formData)
        .then(res => {
          sessionId = res.data.data
          checkInterval = setInterval(() => checkProgress(sessionId), 1000)
        })
        .catch((err) => {
          errorMsg.value = err.response.data.Errors[0]?.Message
          progressPercentage.value = 0
          isUploading.value = false
        })
    }

    const checkProgress = (sessionId: string) => {
      api.get(`manager/usersync/${sessionId}`)
        .then(res => {
          const data: UserUploadEnv = res?.data
          progressPercentage.value = (data?.data.current / data?.data.count) * 100
          tableUsers.value = data.data.results
          if (res?.data.data?.isFinished) {
            isUploading.value = false
            progressPercentage.value = 0
            clearInterval(checkInterval)
            // sorting incorrect entries first
            tableUsers.value.sort((a, b) => a === b ? -1 : 1)
          }
        })
        .catch((err: any) => toastError(toast, err))
    }

    return {
      tableUsers,
      progressPercentage,
      isUploading,
      errorMsg,
      uploadedUserTypeEnum,
      onUpload
    }
  },
})
