
import api from '@/services/api'
import { computed, defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { PaginationBody } from '@/types/Misc'
import { PaginBodyDefault } from '@/consts/PaginationConst'
import { Insurer } from '@/types/Insurer'
import { parseDateLongFormat } from '@/helpers/table-utils'
import { useConfirm } from 'primevue/useconfirm'
import { useToast } from 'primevue/usetoast'
import { toastError } from '@/helpers/error-handling'

export default defineComponent({
  components: {},
  setup() {
    const store = useStore()
    const router = useRouter()
    const toast = useToast()
    const confirm = useConfirm()

    const tableBody = ref<PaginationBody>({ ...PaginBodyDefault })

    store.dispatch('fetchInsurers', tableBody.value)
    store.dispatch('fetchGroups')

    const tableUtilsVisible = ref<boolean>(false)

    const insurerList = computed<Insurer[]>(() => store.state.insurerList?.list || [])
    const insurerCount = computed<number>(() => store.state.insurerList?.pageInfo?.totalCount || 0)

    const addInsurers = () => {
      router.push({name: 'AddInsurerView' })
    }

    const parseVouchersValue = (voucher: number | undefined) => {
      return voucher ? `${voucher} day(s)` : 'Unlimited'
    }

    const insurerDetails = (insurer: Insurer) => {
      router.push(`/insurer-details/${insurer.externalId}`)
    }

    const deleteInsurer = (insurer: Insurer) => {
      confirm.require({
        message: `Are you sure you want to delete '${insurer.name}'?`,
        header: 'Warning',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          api.delete(`manager/insurer/${insurer.externalId}`).then(() => {
            toast.add({ severity: 'info', summary: `${insurer.name} deleted`, life: 3000})
            store.dispatch('fetchInsurers', tableBody.value)
          }).catch((err: Error) => toastError(toast, err))
        }
      })
    }

    const getInsurerUrl = (insurer: Insurer) =>
      insurer.isExternal || insurer.url !== insurer.slug
        ? insurer.url
        : `https://krankenkassen.prosoma.com/de/${insurer.slug}`;

    const paginChange = (payload: PaginationBody) => {
      tableBody.value = { ...payload, pageNumber: payload.pageSize === tableBody.value.pageSize ? payload.pageNumber : 1}
      store.dispatch('fetchInsurers', tableBody.value)
    }

    return {
      tableUtilsVisible,
      insurerList,
      tableBody,
      parseDateLongFormat,
      addInsurers,
      parseVouchersValue,
      insurerDetails,
      deleteInsurer,
      getInsurerUrl,
      insurerCount,
      paginChange
    }
  },
})
