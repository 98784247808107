
import api from '@/services/api'
import { defineComponent, ref, computed } from 'vue'
import { RouterLink } from 'vue-router'
import { PaginationBody } from '@/types/Misc'
import { DeletionUser } from '@/types/User'
import { format } from 'date-fns'
import { PaginBodyDefault } from '@/consts/PaginationConst'
import { useStore } from 'vuex'
import { AccessRole } from '@/enums/UserRole'
import { parseDateLongFormat } from '@/helpers/table-utils'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from 'primevue/useconfirm'
import { toastError } from '@/helpers/error-handling'

export default defineComponent({
  components: {
    RouterLink
  },
  setup() {
    const store = useStore()
    const toast = useToast()
    const confirm = useConfirm()
    
    const tableBody = ref<PaginationBody>({ ...PaginBodyDefault })
    const reasonsVisible = ref<boolean>(false)
    const reasonsForDialog = ref<Array<string>>([])

    store.dispatch('fetchDeletionUsers', tableBody.value)

    const users = computed<DeletionUser[]>(() => store.state.deletionList)
    const userCount = computed<number>(() => store.state.deletionCount)
    const userRole = computed(() => store.state.userType)

    let searchTimeout = ref<number | null>(0)

    const tableUtilsVisible = computed<number>(() => {
      return users.value.filter(user => user.selected).length
    })
    const areUsersSelected = computed<boolean>(() => {
      return tableUtilsVisible.value ? true : false
    })

    const returnDate = (ts: number): string => {
      return ts ? format(new Date(ts * 1000), 'dd-MM-yyyy HH:mm:ss') : '-'
    }

    const searchUsers = () => {
      if (searchTimeout.value) {
        clearTimeout(searchTimeout.value)
        searchTimeout.value = null
      }
      searchTimeout.value = window.setTimeout(() => {
        const searchLength = tableBody.value.searchValue.length
        if (searchLength >= 3 || searchLength === 0) {
          tableBody.value.pageNumber = 1
          store.dispatch('fetchDeletionUsers', tableBody.value)
        }
      },800)
    }

    const paginChange = (payload: PaginationBody) => {
      tableBody.value = { ...payload }
      store.dispatch('fetchDeletionUsers', tableBody.value)
    }

    const toggleAllItems = () => {
      if (areUsersSelected.value) {
        users.value.forEach(item => item.selected = false)
      } else {
        users.value.forEach(item => item.selected = true)
      }
    }

    const restoreAccount = (id: string) => {
      confirm.require({
        message: 'Are you sure you want to restore selected user?',
        header: 'Warning',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          await api.post('manager/userrequest/deny', JSON.stringify({externalId: id}))
            .then(() => {
              store.dispatch('fetchDeletionUsers', tableBody.value)
              toast.add({ severity: 'info', summary: 'User restored', detail: 'Selected user restored', life: 3000 })
            })
            .catch((err: any) => toastError(toast, err))
        },
        reject: () => {
          toast.add({ severity: 'info', summary: 'Action cancelled', life: 3000})
        }
      })
    }

    const acceptDeletion = (id: string) => {
      confirm.require({
        message: 'Are you sure you want to permanently delete selected user?',
        header: 'Warning',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          await api.post('manager/userrequest/accept', JSON.stringify({externalId: id}))
            .then(() => {
              store.dispatch('fetchDeletionUsers', tableBody.value)
              toast.add({ severity: 'info', summary: 'User deleted', detail: 'Selected user deleted', life: 3000 })
            })
            .catch((err: any) => toastError(toast, err))
        },
        reject: () => {
          toast.add({ severity: 'info', summary: 'Action cancelled', life: 3000})
        }
      })
    }

    const showReasons = (reasons: string[] | null | undefined) => {
      reasonsVisible.value = true
      reasonsForDialog.value = []
      reasonsForDialog.value = [...(reasons as string[])]
    }

    const restoreSelectedAccounts = () => {
      confirm.require({
        message: 'Are you sure you want to restore selected users?',
        header: 'Warning',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          const idsToRestore = users.value.reduce((a, o) => (o.selected && a.push(o.externalId), a), [] as string[])
          await api.post('manager/userrequest/deny/many', JSON.stringify({externalIds: idsToRestore}))
            .then(() => {
              store.dispatch('fetchDeletionUsers', tableBody.value)
              toast.add({ severity: 'info', summary: 'Users restored', detail: 'Selected users restored', life: 3000 })
            })
            .catch((err: any) => toastError(toast, err))
        },
        reject: () => {
          toast.add({ severity: 'info', summary: 'Action cancelled', life: 3000})
        }
      })
    }

    const deleteSelectedAccounts = () => {
      confirm.require({
        message: 'Are you sure you want to permanently delete selected users?',
        header: 'Warning',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          const idsToDelete = users.value.reduce((a, o) => (o.selected && a.push(o.externalId), a), [] as string[])
          await api.post('manager/userrequest/accept/many', JSON.stringify({externalIds: idsToDelete}))
            .then(() => {
              store.dispatch('fetchDeletionUsers', tableBody.value)
              toast.add({ severity: 'info', summary: 'Users deleted', detail: 'Selected users deleted', life: 3000 })
            })
            .catch((err: any) => toastError(toast, err))
        },
        reject: () => {
          toast.add({ severity: 'info', summary: 'Action cancelled', life: 3000})
        }
      })
    }

    return {
      users,
      userCount,
      tableBody,
      userRole,
      AccessRole,
      areUsersSelected,
      tableUtilsVisible,
      reasonsVisible,
      reasonsForDialog,
      searchUsers,
      returnDate,
      paginChange,
      toggleAllItems,
      parseDateLongFormat,
      restoreAccount,
      acceptDeletion,
      showReasons,
      restoreSelectedAccounts,
      deleteSelectedAccounts
    }
  },
})
