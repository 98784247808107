
import api from '@/services/api'
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from 'primevue/useconfirm'
import { Moderator } from '@/types/Moderator'
import { PaginationBody } from '@/types/Misc'
import { PaginBodyDefault } from '@/consts/PaginationConst'
import { toastError } from '@/helpers/error-handling'

export default defineComponent({
  components: {},
  setup() {
    const router = useRouter()
    const toast = useToast()
    const confirm = useConfirm()

    const moderators = ref<Moderator[]>([])
    const moderatorCount = ref<number>(0)
    const tableBody = ref<PaginationBody>({ ...PaginBodyDefault })

    const fetchModerators = () => {
      api.put('admin/user/filter', JSON.stringify(tableBody.value))
      .then(res => {
        moderators.value = res?.data.data.list
        moderatorCount.value = res?.data.data.pageInfo.totalCount
      })
      .catch((err: Error) => toastError(toast, err))
    }

    onMounted(() => {
     fetchModerators()
    })

    const tableUtilsVisible = computed(() => {
      return moderators.value.filter(mod => mod.selected).length
    })
    const areUsersSelected = computed(() => {
      return tableUtilsVisible.value ? true : false
    })

    const addModerator = () => {
      router.push('/add-management')
    }

    const deleteUsers = () => {
      confirm.require({
        message: 'Are you sure you want to delete selected users?',
        header: 'Warning',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          toast.add({ severity: 'info', summary: 'Users deleted', detail: 'Selected users deleted', life: 3000 })
        },
        reject: () => {
          toast.add({ severity: 'info', summary: 'Action cancelled', life: 3000})
        }
      })
    }

    const paginChange = (payload: PaginationBody) => {
      tableBody.value = { ...payload, pageNumber: payload.pageSize === tableBody.value.pageSize ? payload.pageNumber : 1}
      fetchModerators()
    }

    return {
      moderators,
      tableUtilsVisible,
      areUsersSelected,
      addModerator,
      deleteUsers,
      tableBody,
      moderatorCount,
      paginChange
    }
  },
})
