
import { defineComponent, ref } from 'vue'

export default defineComponent({
  props: ['group'],
  components: {},
  setup(props) {
    const activeTab = ref<number>(0)

    const tabMenu = [
      { label: 'Type Informations', to: `/add-user/${props.group}/single` },
      { label: 'Add Clinic Users', to: `/add-user/${props.group}/batch` },
      { label: 'Import from CSV', to: `/add-user/${props.group}/import`}
    ]

    const onTabChange = (_event: any) => {
      activeTab.value = _event.index
    }

    return {
      tabMenu,
      activeTab,
      onTabChange
    }
  }
})
