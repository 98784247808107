
import { defineComponent, ref } from 'vue'
import { useRouter, RouterLink } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
  components: {
    RouterLink
  },
  setup() {
    const router = useRouter()
    const store = useStore()

    return {

    }
  },
})
